
    import { defineComponent, ref, reactive, toRefs } from 'vue';
    import useFetchCommon from "@/hooks/useFetchCommon";
    import { getAllBu, getRegion, deleteRegion } from '@/API/dealerMaster';
    import { Bu, RegionListProps } from "@/views/DealerMasterData/Region/types";
    import useTableHeight from "@/hooks/useTableHeight";
    import NewAddEditRegion from "@/views/DealerMasterData/Region/components/NewAddEditRegion.vue";
    import Pagination from "@/components/Pagination.vue";
    import { message } from "ant-design-vue";

    const columns = [
        {
            title: 'Business Unit',
            dataIndex: 'buNameCn',
            key: 'buNameCn',
            width: 220
        },
        {
            title: 'Region En',
            dataIndex: 'regionEn',
            key: 'regionEn',
            width: 240
        },
        {
            title: 'Region Cn',
            dataIndex: 'regionCn',
            key: 'regionCn',
            width: 240
        },
        {
            title: 'Sub Region En',
            dataIndex: 'subRegionEn',
            key: 'subRegionEn',
            width: 150
        },
        {
            title: 'Sub Region Cn',
            dataIndex: 'subRegionCn',
            key: 'subRegionCn',
            width: 150
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            key: 'operation',
            slots: { customRender: 'operation' },
            width: 150
        }
    ];

    export default defineComponent({
        name: "Region",
        components: {
            NewAddEditRegion,
            Pagination
        },
        setup() {
            const { tableHeight } = useTableHeight();

            const visible = ref(false);

            const regionId = ref('');

            const regionQueryParams = reactive<{ buId: number | undefined }>({
                buId: undefined
            })

            const { data: buOptions } = useFetchCommon<Bu[]>({ params: {}, initData: [], fetchApi: getAllBu });

            const { data, pagination, refreshFetch } = useFetchCommon<RegionListProps[]>({ params: regionQueryParams, initData: [], fetchApi: getRegion, isPagination: true });

            const handlerReset = () => {
                regionQueryParams.buId = undefined;
                refreshFetch();
            }

            const handlerNewRegion = () => {
                visible.value = true;
            }

            const handlerEdit = (_regionId: string) => {
                regionId.value = _regionId;
                handlerNewRegion();
            }

            const handlerDelete = (regionId: string) => {
                deleteRegion({ id: regionId }).then(res => {
                    message.success('Delete Region successfully');
                    refreshFetch();
                })
            }

            //分页事件处理
            const handlerPaginationChange = (page: number): void => {
                pagination.page = page;
                refreshFetch(false);
            }

            const handlerShowSizeChange = (current: number, pageSize: number): void => {
                pagination.size = pageSize;
                Object.assign(pagination, { page: current, size: pageSize });
                refreshFetch(false);
            }

            return {
                tableHeight,
                columns,
                data,
                pagination,

                buOptions,
                visible,
                regionId,

                ...toRefs(regionQueryParams),

                handlerReset,
                handlerEdit,
                handlerDelete,
                handlerNewRegion,
                refreshFetch,
                handlerPaginationChange,
                handlerShowSizeChange
            }
        }
    })
